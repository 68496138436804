
<template>
  <div class="main-content">



    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>


    <br/>
    <b-table striped hover :items="campaigns" :fields="columns">
      <template v-slot:cell(loyaltyId)="data">
        <a style="text-decoration: underline; font-weight: bolder;" :href="'/customer/'+data.item.id">{{ data.value }}</a>
      </template>
      <template v-slot:cell(status)="data">
            <span  class="badge badge-success"  v-if="data.value == 'SENT'">
                   {{ data.value }}
                </span>
        <span class="badge badge-danger"  v-else >
                  {{ data.value }}
            </span>
      </template>
      <template v-slot:cell(options)="data">

        <b-dropdown text="options">
          <b-dropdown-item href="#"  >Convert to lead</b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

  </div>
</template>
<script>

  import { mapGetters, mapActions } from "vuex";
  import DatePicker from 'vue2-datepicker';
  import 'vue2-datepicker/index.css';
  import CodeInput from "vue-verification-code-input"
  import Multiselect from 'vue-multiselect';

  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css'


export default {
  data() {
    return {
      phoneNumberDuplicate:"",
      phoneNumber:"",
      offset: 4,
      pref:"254",
      disabled:false,
      isLoading: false,
      fullPage: true,
      columns:[{
        key: 'created',
        label:'Date',
        sortable: false,
      },"message",{
        key: 'msisdn',
        label:'Phone Number',
        sortable: false,
      },"options"],
      campaigns:[],
      outlets:[],
      pagination: {
        total: 0,
        per_page: 2,
        from: 1,
        to: 0,
        current_page: 0
      },
      customers:[]
    };
  },
  components: {
    Loading,
    DatePicker,
    Multiselect
  },
  computed:{
    isActived: function () {
      return this.pagination.current_page + 1;
    },
    pagesNumber: function () {
      if (!this.pagination.to) {
        return [];
      }
      var from = this.pagination.current_page - this.offset;
      if (from < 1) {
        from = 1;
      }
      var to = from + (this.offset * 2);
      if (to >= this.pagination.last_page) {
        to = this.pagination.last_page;
      }
      var pagesArray = [];
      while (from <= to) {
        pagesArray.push(from);
        from++;
      }
      return pagesArray;
    }
  },
  mounted (){
    this.getCampaignsData();
  },
  methods: {
    ...mapActions(["getShortCodeMessages"]),

    getCampaignsData(){
      let self = this;
      this.getShortCodeMessages()
        .then(function (campaigns) {

          self.campaigns = campaigns;

        })
        .catch(function (error) {


          if( typeof error.response.status != "undefined"){
            if(error.response.status == 401){
              location.replace("/login");
            }

          }
        })
    },


    validateBeforeSubmitNewCampaign(){

      let self = this;

      self.isLoading = true;

      this.sms.customers = this.sms.customers.map(function (customer){
          return customer.id;
      });

      this.sms.outlets = this.sms.outlets.map(function (outlet){
        return outlet.id;
      });


      this.createSMS( this.sms)
        .then(function () {

          self.isLoading = false;

          self.$bvToast.toast("Campaign added.", {
            title: 'Success',
            variant: 'success',
            autoHideDelay: 5000,
            appendToast: false
          });

          self.getCampaignsData();

        })
        .catch(function (error) {

          self.isLoading = false;

        })
    },
  }
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
